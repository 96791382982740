<script setup lang="ts">
import { BaseButton, BaseIcon, BaseTabs } from '@legacy-studio/library';

import {
	useAddChartDrawingItem,
	useChartDrawingItems,
	type ChartDrawing,
} from '@/src/features/terminal';
import { useChartChangeShapeById } from '~/src/features/terminal/core/application';
import { useSidebarStore } from '~/src/widgets/terminal/store';

const addChartDrawingItem = useAddChartDrawingItem();
const currentDrawingItems = useChartDrawingItems();

const chartChangeShapeById = useChartChangeShapeById();

const activeTab = ref('list');

const tabs = [
	{
		code: 'list',
		name: 'Инструменты',
		icon: 'base--fill--pencil',
	},
	{
		code: 'added',
		name: 'Добавленные',
		icon: 'base--stroke--collection',
	},
];

const allDrawingItems = [
	{
		text: 'Горизонтальная линия',
		value: 'horizontal_line',
	},
	{
		text: 'Вертикальная линия',
		value: 'vertical_line',
	},
	{
		text: 'Луч',
		value: 'ray',
	},
	{
		text: 'Коррекция по Фибоначчи',
		value: 'fib_retracement',
	},
	{
		text: 'Веерные линии по Фибоначчи',
		value: 'fib_speed_resist_fan',
	},
	{
		text: 'Линия тренда',
		value: 'trend_line',
	},
	{
		text: 'Паралленьные Каналы',
		value: 'parallel_channel',
	},
	{
		text: 'Прямоугольник',
		value: 'rectangle',
	},
	{
		text: 'Вилы',
		value: 'pitchfork',
	},
].map(item => ({
	text: item.text,
	value: item.value as ChartDrawing,
	icon: `drawing--fill--${item.value}`,
	isVisible: true,
	isFrozen: false,
}));
const sidebarStore = useSidebarStore();
const onChange = (item: {
	text: string;
	value: ChartDrawing;
	icon: string;
}) => {
	sidebarStore.closeDrawer();
	addChartDrawingItem(item);
};

// const toggleFrozen = (button: any) => {
// 	button.isFrozen = !button.isFrozen;
// 	chartChangeShapeById(button, 'lock');
// };

const onToggleVisibility = (button: any) => {
	button.isVisible = !button.isVisible;
	chartChangeShapeById(button, 'visibility');
};

const onDelete = (button: any) => {
	chartChangeShapeById(button, 'delete');
};
</script>

<template>
	<div
		class="flex flex-col gap-4"
	>
		<BaseTabs
			v-model="activeTab"
			:items="tabs"
		/>

		<div
			v-if="activeTab === 'list'"
			class="flex flex-col gap-4"
		>
			<BaseButton
				v-for="button in allDrawingItems"
				:key="button.value"
				color="alternative-dark"
				:left-icon-style="button.icon"
				size="base"
				outline
				:button-text="button.text"
				@click="onChange(button)"
			/>
		</div>

		<div
			v-else
			class="flex flex-col text-white"
		>
			<div
				v-for="button in currentDrawingItems"
				:key="button.value"
				class="flex justify-between items-center py-3 bg-gray-800 hover:bg-gray-700 border-b border-gray-600 gap-3"
			>
				<div class="flex items-center gap-3">
					<BaseIcon
						:name="button.icon"
						class="text-white text-[24px]"
					/>
					<span class="text-white text-base font-medium">{{ button.text }}</span>
				</div>

				<div class="flex items-center gap-3">
					<!--						<BaseIcon -->
					<!--							:name="button.isFrozen ? 'base&#45;&#45;fill&#45;&#45;lock-closed' : 'base&#45;&#45;stroke&#45;&#45;lock-open'" -->
					<!--							class="text-white text-[24px] cursor-pointer" -->
					<!--							@click="toggleFrozen(button)" -->
					<!--						/> -->
					<BaseIcon
						:name="button.isVisible ? 'base--stroke--eye-off' : 'base--stroke--eye'"
						class="text-white text-[24px] cursor-pointer"
						@click="onToggleVisibility(button)"
					/>
					<BaseIcon
						name="base--stroke--trash"
						class="text-white text-[24px] cursor-pointer"
						@click="onDelete(button)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
